import React from "react";
import "./styles.scss";

interface ParagraphProps {
  text?: string;
  color?: "white" | "black" | "gray-dark" | "dust" | "gray" | "faded";
}

const Paragraph: React.FC<ParagraphProps> = (props) => {
  const { text, color = "gray-dark" } = props;
  return (
    <p id="paragraph-component" className={`paragraph-color-${color}`}>
      {text}
    </p>
  );
};

export default Paragraph;
