import React from "react";

interface VideoPlayerProps {
  videoSrc: string;
  width?: number;
  height?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoSrc,
  width = 1224,
  height = "auto",
}) => (
  <div id="video-wrapper">
    <video width={width} height={height} controls>
      <source src={videoSrc} type="video/mp4" />
    </video>
  </div>
);

export default VideoPlayer;
