import { createBrowserRouter } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import AboutUsPage from "../pages/AboutUsPage";
import LiveStreamPage from "../pages/LiveStreamPage";
import UpcomingEventsPage from "../pages/UpcomingEventsPage";
import PressReleasePage from "../pages/PressReleasePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/about-us",
    element: <AboutUsPage />,
  },
  {
    path: "/watch-past-event",
    element: <LiveStreamPage />,
  },
  {
    path: "/upcoming-events",
    element: <UpcomingEventsPage />,
  },
  {
    path: "/press-release",
    element: <PressReleasePage />,
  },
]);

export default router;
