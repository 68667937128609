import React, { useEffect, useState } from "react";
import "./styles.scss";
import TitleHr from "../../Hr/TitleHr";
import Button from "../../Buttons/Button";
import FighterImage from "../../ImageMask/FighterImage";
import CarouselDefault from "../../Carousels/CarouselsDefault";
import { useNavigate } from "react-router-dom";

import pastEvent01 from "../../../assets/images/pastEvents/CFN1 - WATCH THE STREAM.jpg";
import pastEvent02 from "../../../assets/images/pastEvents/CFN2 - WATCH THE STREAM.jpg";
import pastEvent03 from "../../../assets/images/pastEvents/CFN3 - WATCH THE STREAM.jpg";
import pastEvent04 from "../../../assets/images/pastEvents/CFN4 - WATCH THE STREAM.jpg";
import pastEvent05 from "../../../assets/images/pastEvents/CFN LONDON - WATCH THE STREAM.jpg";

interface PastEventsWrapperProps {
  titleColor: "white" | "black";
}

const PastEventsWrapper: React.FC<PastEventsWrapperProps> = ({
  titleColor,
}) => {
  const [screenType, setScreenType] = useState<
    "mobile" | "tablet" | "widescreen"
  >(
    window.innerWidth < 768
      ? "mobile"
      : window.innerWidth < 1024
      ? "tablet"
      : "widescreen"
  );
  const [slidesPerView, setSlidesPerView] = useState(
    window.innerWidth < 768 ? 1 : window.innerWidth < 1024 ? 2 : 3
  );
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setScreenType("mobile");
        setSlidesPerView(1);
      } else if (window.innerWidth < 1024) {
        setScreenType("tablet");
        setSlidesPerView(2);
      } else {
        setScreenType("widescreen");
        setSlidesPerView(3);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePastEventNavigation = () => {
    navigate(`/watch-past-event`);
  };

  const imgCarouselForm = [
    {
      id: "1",
      img: pastEvent05,
      onClick: () => handlePastEventNavigation(),
    },
    {
      id: "2",
      img: pastEvent04,
      onClick: () => handlePastEventNavigation(),
    },
    {
      id: "3",
      img: pastEvent03,
      onClick: () => handlePastEventNavigation(),
    },
    {
      id: "4",
      img: pastEvent02,
      onClick: () => handlePastEventNavigation(),
    },
    {
      id: "5",
      img: pastEvent01,
      onClick: () => handlePastEventNavigation(),
    },
  ];

  const handleNavigation = () => {
    navigate("/watch-past-event");
  };

  return (
    <div id="past-events-wrapper">
      <div className="title-hr-wrapper">
        <TitleHr
          title={"PAST EVENTS"}
          titleColor={titleColor}
          subTitleColor={"gray"}
          mainTitle={false}
        />
      </div>
      <div className="past-events-wrapper">
        <CarouselDefault
          imagesCarousel={imgCarouselForm}
          slidesPerView={slidesPerView}
          eventsCarousel
        />
        <div className="buttons-wrapper">
          {/* <Button children={"Champions List"} buttonStyle={"quaternary"} /> */}
          <Button
            children={"Watch Now"}
            buttonStyle={"tertiary"}
            onClick={handleNavigation}
          />
        </div>
      </div>
    </div>
  );
};

export default PastEventsWrapper;
