import React from "react";
import "./styles.scss";
import TitleHr from "../../Hr/TitleHr";
import { useNavigate } from "react-router-dom";
import { Event } from "../../../types/types";

import upcomingEvent01 from "../../../assets/images/upcomingEvents/cfn5/event.jpg";

interface UpcomingEventsWrapperProps {
  onEventSelect: (event: Event) => void;
}

const UpcomingEventsWrapper: React.FC<UpcomingEventsWrapperProps> = ({
  onEventSelect,
}) => {
  const navigate = useNavigate();

  const handleClick = (event: Event) => {
    onEventSelect(event);
    navigate("/upcoming-events", { state: { eventId: event.id } });
  };

  return (
    <div id="upcoming-events-wrapper">
      <div className="title-hr-wrapper">
        <TitleHr
          title={"UPCOMING EVENTS"}
          titleColor={"white"}
          mainTitle={false}
        />
      </div>
      <div className="upcoming-events-wrapper">
        <img
          src={upcomingEvent01}
          alt="Upcoming Event 02"
          onClick={() =>
            handleClick({
              id: "2",
              title: "CFN 5",
              subTitle: "DUBAI | 24 DEC 2024",
              videoUrls: [],
              img: upcomingEvent01,
              bannerUrl: upcomingEvent01,
            })
          }
        />
      </div>
    </div>
  );
};

export default UpcomingEventsWrapper;
