import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import UpcomingEventsWrapper from "../../components/Wrappers/UpcomingEventsWrapper";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import PastEventsWrapper from "../../components/Wrappers/PastEventsWrapper";
import NewsLetterWrapper from "../../components/Wrappers/NewsLetterWrapper";
import { Event } from "../../types/types";

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleEventSelection = (event: Event) => {
    console.log("Selected Event ID in LandingPage:", event.id);
    navigate("/upcoming-events", { state: { eventId: event.id } });
  };

  return (
    <>
      <LayoutDefault>
        <NewsLetterWrapper />
        <UpcomingEventsWrapper onEventSelect={handleEventSelection} />
        <PastEventsWrapper titleColor="black" />
      </LayoutDefault>
    </>
  );
};

export default LandingPage;
