import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";
import NavBar from "../../NavBar/index.";
import FeaturedCarousel from "../../Carousels/FeaturedCarousel";
import Footer from "../../Footer";

import bannerWide from "../../../assets/images/banners/cfn5/desktop.jpg";
import bannerMobile from "../../../assets/images/banners/cfn5/mobile.jpg";
import NavBarTest from "../../NavBar/NavBar1.0";

interface LayoutDefaultProps {
  children: ReactNode;
  className?: string;
  showFeaturedCarousel?: boolean;
}

const imgCarouselWide = [
  {
    id: "1",
    img: bannerWide,
  },
];
const imgCarouselMobile = [
  {
    id: "1",
    img: bannerMobile,
  },
];

const LayoutDefault: React.FC<LayoutDefaultProps> = ({
  children,
  className,
  showFeaturedCarousel = true,
}) => {
  const [isAbove1024px, setIsAbove1024px] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsAbove1024px(window.innerWidth > 625);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="layout-default" className={className}>
      <NavBarTest />
      {showFeaturedCarousel && (
        <FeaturedCarousel
          imagesCarousel={isAbove1024px ? imgCarouselWide : imgCarouselMobile}
        />
      )}
      <div className="main-container">{children}</div>
      <Footer />
    </div>
  );
};

export default LayoutDefault;
