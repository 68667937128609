import React from "react";
import "./styles.scss";
import EmailIcon from "../../assets/icons/social-medias/email-icon.svg";
import TwitterLogo from "../../assets/icons/social-medias/twitter-logo.svg";
import InstagramLogo from "../../assets/icons/social-medias/instagram-logo.svg";

interface FooterProps {}

const Footer: React.FC<FooterProps> = (props) => {
  const {} = props;

  return (
    <footer id="footer-default">
      <div className="copy-right">
        <p>CFN - Crypto Fight Night | Copyright © | All Rights 2024</p>
        <div className="social-medias">
          <a href="mailto:support@cfn.wtf">
            <img src={EmailIcon} alt="email icon" />
          </a>
          <a href="https://x.com/cryptofightweek" target="_blank">
            <img src={TwitterLogo} alt="twitter icon" />
          </a>
          <a href="https://www.instagram.com/cryptofightweek/" target="_blank">
            <img src={InstagramLogo} alt="instagram icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
