import React from "react";
import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import FighterImage from "../../ImageMask/FighterImage";

interface CarouselDefaultProps {
  imagesCarousel: {
    id: string;
    img: string;
    firstName?: string;
    lastName?: string;
    onClick?: () => void;
  }[];
  slidesPerView?: number;
  fighterCarousel?: boolean;
  eventsCarousel?: boolean;
  centeredImages?: boolean;
}

const CarouselDefault: React.FC<CarouselDefaultProps> = ({
  imagesCarousel,
  slidesPerView = 3,
  fighterCarousel = false,
  eventsCarousel = false,
  centeredImages = false,
}) => {
  const breakpoints = centeredImages
    ? {
        0: {
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
        800: {
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
        // 1224: {
        //   slidesOffsetBefore: 0,
        //   slidesOffsetAfter: 0,
        // },
      }
    : {
        0: {
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
        800: {
          slidesOffsetBefore: 50,
          slidesOffsetAfter: 50,
        },
        // 1224: {
        //   slidesOffsetBefore: 100,
        //   slidesOffsetAfter: 100,
        // },
      };
  return (
    <Swiper
      spaceBetween={20}
      slidesPerView={slidesPerView}
      navigation={true}
      breakpoints={breakpoints}
      className={`swiper-container ${centeredImages ? "centered" : ""}`}
      modules={[Navigation, Pagination]}
    >
      {imagesCarousel.map((img) => (
        <SwiperSlide
          key={img.id}
          className={`swiper-slide ${eventsCarousel ? "swiper-events" : ""}`}
        >
          {fighterCarousel && (
            <FighterImage
              img={img.img}
              firstName={img.firstName}
              lastName={img.lastName}
            />
          )}
          {eventsCarousel && (
            <img
              src={img.img}
              alt="events"
              onClick={img.onClick}
              className="clickable-image"
            />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CarouselDefault;
