import React, { useEffect } from "react";
import "./styles.scss";
import FightersWrapper from "../../components/Wrappers/FightersWrapper";
import Footer from "../../components/Footer";
import UpcomingEventsWrapper from "../../components/Wrappers/UpcomingEventsWrapper";
import CarouselDefault from "../../components/Carousels/CarouselsDefault";

import ImageBoat from "../../assets/images/image_fighter.png";
import FightImage from "../../components/ImageMask/FightImage";
import LiveStreamWrapper from "../../components/Wrappers/LiveStreamWrapper";
import ExperienceWrapper from "../../components/Wrappers/ExperienceWrapper";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";

import AboutUsWrapper from "../../components/Wrappers/AboutUsWrapper";
import PressReleaseWrapper from "../../components/Wrappers/PressReleaseWrapper";

const PressReleasePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LayoutDefault showFeaturedCarousel={false}>
        <PressReleaseWrapper />
      </LayoutDefault>
    </>
  );
};

export default PressReleasePage;
