import React, { useState } from "react";
import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import "swiper/scss/effect-fade";

interface FeaturedCarouselProps {
  imagesCarousel: {
    id: string;
    img: string;
  }[];
}

const FeaturedCarousel: React.FC<FeaturedCarouselProps> = (props) => {
  const { imagesCarousel } = props;

  return (
    <Swiper
      effect="fade"
      centeredSlides={true}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false,
      }}
      speed={2000}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation, EffectFade]}
      id="featured-carousel"
    >
      {imagesCarousel.map((img) => (
        <SwiperSlide key={img.id} className="featured-carousel-container">
          <img src={img.img} alt={img.id} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default FeaturedCarousel;
