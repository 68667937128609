import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/reset.scss";
import "./assets/scss/var.scss";
import "./assets/scss/global.scss";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  // apiKey: "AIzaSyAx5aSROx3HSCfJ0O5gn3BAym0e4r1fzF4",
  authDomain: "cfn-wtf-2d1a9.firebaseapp.com",
  projectId: "cfn-wtf-2d1a9",
  // storageBucket: "cfn-wtf-2d1a9.appspot.com",
  // messagingSenderId: "262777359467",
  // appId: "1:262777359467:web:7d6fbb4b4a5bfad0b9352e",
  // measurementId: "G-GEN2JDQ7T0"
};

const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
