import React from "react";
import "./styles.scss";
import TitleHr from "../../Hr/TitleHr";
import Button from "../../Buttons/Button";
import NewsLetterForm from "../../Forms/NewsLetterForm";
import Paragraph from "../../Texts/Paragraph";

const NewsLetterWrapper: React.FC = () => {
  return (
    <div id="newsletter-wrapper">
      <div className="title-hr-wrapper">
        <TitleHr title={"NEWSLETTER"} titleColor={"black"} mainTitle={false} />
      </div>
      <div className="description-wrapper">
        <Paragraph
          color="gray"
          text="Stay in the ring with Crypto Night Fight! Subscribe now to receive exclusive updates, behind-the-scenes insights, and more straight to your inbox."
        />
      </div>
      <NewsLetterForm />
    </div>
  );
};

export default NewsLetterWrapper;
