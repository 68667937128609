import React, { useEffect, useState } from "react";
import "./styles.scss";
import TitleHr from "../../../Hr/TitleHr";
import { Event } from "../../../../types/types";

interface WatchUpcomingStreamWrapperProps {
  selectedEvent: Event;
}

const WatchUpcomingStreamWrapper: React.FC<WatchUpcomingStreamWrapperProps> = ({
  selectedEvent,
}) => {
  const [currentBannerUrl, setCurrentBannerUrl] = useState<string>(
    selectedEvent.bannerUrl || ""
  );

  useEffect(() => {
    if (selectedEvent.bannerUrl) {
      setCurrentBannerUrl(selectedEvent.bannerUrl);
    }
  }, [selectedEvent]);

  return (
    <div id="watch-upcoming-stream-wrapper">
      <div className="title-hr-wrapper">
        <TitleHr
          title={selectedEvent.title}
          titleColor={"white"}
          subTitle={selectedEvent.subTitle}
          subTitleColor="dust"
          mainTitle
        />
      </div>
      <div className="banner-wrapper">
        {currentBannerUrl && <img src={currentBannerUrl} alt="Event Banner" />}
      </div>
    </div>
  );
};

export default WatchUpcomingStreamWrapper;
