import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import WatchUpcomingStreamWrapper from "../../components/Wrappers/LiveStreamWrapper/WatchUpcomingStreamWrapper";
import UpcomingEventsWrapper from "../../components/Wrappers/UpcomingEventsWrapper";
import { Event } from "../../types/types";

import upcomingEvent01 from "../../assets/images/upcomingEvents/cfn5/event.jpg";

const UpcomingEventsPage: React.FC = () => {
  const location = useLocation();
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const eventId = location.state?.eventId;
    console.log("Event ID from Location State:", eventId);

    if (eventId) {
      const fetchEventById = (id: string): Event | null => {
        const events: Event[] = [
          {
            id: "1",
            title: "CFN 5",
            subTitle: "DUBAI | 24 DEC 2024",
            videoUrls: [],
            img: upcomingEvent01,
            bannerUrl: upcomingEvent01,
          },
        ];
        return events.find((event) => event.id === id) || null;
      };

      const event = fetchEventById(eventId);
      if (event) {
        console.log("Event Selected in UpcomingEventsPage:", event);
        setSelectedEvent(event);
      } else {
        console.warn("Event not found.");
      }
    } else {
      console.warn("No event ID found in location state.");
    }
  }, [location.state]);

  return (
    <>
      <LayoutDefault showFeaturedCarousel={false}>
        {selectedEvent ? (
          <WatchUpcomingStreamWrapper selectedEvent={selectedEvent} />
        ) : (
          <p>No event selected.</p>
        )}
        <UpcomingEventsWrapper
          onEventSelect={(event) => {
            console.log("Event Selected in UpcomingEventsPage:", event);
            setSelectedEvent(event);
          }}
        />
      </LayoutDefault>
    </>
  );
};

export default UpcomingEventsPage;
