import React, { ReactNode } from "react";
import "./styles.scss";

interface TitleProps {
  children: ReactNode;
  color?: "black" | "white";
  mainTitle?: boolean;
}

const Title: React.FC<TitleProps> = (props) => {
  const { children, color = "black", mainTitle = true } = props;
  const Tag = mainTitle ? "h1" : "h2";
  return (
    <Tag id="title-component" className={`title-color-${color}`}>
      {children}
    </Tag>
  );
};

export default Title;
