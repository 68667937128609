import React, { useState } from "react";
import Button from "../../Buttons/Button";
import "./styles.scss";
import Paragraph from "../../Texts/Paragraph";

const NewsLetterForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;

    // Verifica se o campo de email está preenchido
    if (!email) {
      setError("Please fill in the Email Address field.");
      setSuccess("");
      setTimeout(() => setError(""), 5000);
      return;
    }

    const formData = new FormData(form);

    fetch(
      "https://wtf.us17.list-manage.com/subscribe/post?u=d1e6f568de5f19082ad84704d&amp;id=051ed9843f&amp;f_id=00f44ce0f0",
      {
        method: "POST",
        body: formData,
        mode: "no-cors",
      }
    )
      .then(() => {
        setSuccess("Thank you for subscribing!");
        setError("");
        setEmail("");
        setFirstName("");
        setTimeout(() => setSuccess(""), 3000);
      })
      .catch(() => {
        setError("An error occurred. Please try again.");
        setSuccess("");
        setTimeout(() => setError(""), 3000);
      });
  };

  return (
    <div id="mc-embed-shell">
      <div className="mc-embed-signup">
        <form
          onSubmit={handleSubmit}
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_self"
          noValidate
        >
          <div className="mc-embed-signup-scroll">
            <div className="mc-field-group">
              {/* <label htmlFor="mce-EMAIL">
                Email Address <span className="asterisk">*</span>
              </label> */}
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required
                value={email}
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button type="submit" buttonStyle="primary">
                Subscribe
              </Button>
            </div>
            <div
              aria-hidden="true"
              style={{ position: "absolute", left: "-5000px" }}
            >
              {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
              <input
                type="text"
                name="b_d1e6f568de5f19082ad84704d_051ed9843f"
                tabIndex={-1}
                value=""
                readOnly
              />
            </div>
            <div className="mce-responses clear foot">
              {error && <div className="response error">{error}</div>}
              {success && <div className="response success">{success}</div>}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsLetterForm;
