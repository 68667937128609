import React from "react";
import "./styles.scss";
import TitleHr from "../../Hr/TitleHr";
import Paragraph from "../../Texts/Paragraph";
import VideoPlayer from "../../VideoPlayer";
import sponsorVideo from "../../../assets/videos/Sponsors-.mp4";
import Button from "../../Buttons/Button";

const handleEmail = () => {
  window.location.href = "mailto:support@cfn.wtf";
};

const PressReleaseWrapper: React.FC = () => {
  return (
    <div id="press-release-wrapper">
      <div className="content-wrapper-inner">
        <TitleHr
          title={"Press Release"}
          titleColor={"white"}
          mainTitle={true}
        />
      </div>
      <ul className="press-release-list">
        <li>
          <h4>The Mirror</h4>
          <p>Cyborg Interview & Preview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.mirror.co.uk/sport/boxing/cris-cyborg-claims-kayla-harrison-33035817",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>The Sun</h4>
          <p>Jack Fincham Interview & Preview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.thesun.co.uk/sport/28431509/jack-fincham-love-island-tommy-fury-boxing-weight-loss/",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>SPORF</h4>
          <p>Cyborg Interview & Preview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.sporf.com/shes-not-just-a-model-cris-cyborg-talks-clash-with-aria-wild-and-playing-the-guitar-for-her-parrot/",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Title Sports Network</h4>
          <p>Cyborg Interview & Preview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?si=ABL7U-PLOfnvZZ_1&v=bc3rOIzxe1U&feature=youtu.be",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>The MMA Fan Show</h4>
          <p>Cyborg Interview & Preview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=nJvMy3LM_BM",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>MMA UK</h4>
          <p>Cyborg Interview & Preview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/live/fvcSfIlBAJA",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Bloody Elbow</h4>
          <p>Cyborg Interview & Preview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://bloodyelbow.com/2024/06/15/cris-cyborg-plots-busy-year-of-boxing-and-mma-as-she-makes-uk-debut-after-almost-20-years-of-fighting/",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>MMA Junkie</h4>
          <p>Cyborg Interview & Preview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?si=hTiTzezMOukLGnhs&v=1SeAmyM6QJI&feature=youtu.be",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>MMA Mania</h4>
          <p>Cyborg Interview & Preview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=ThrAOvq5SZo",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Grind City Media</h4>
          <p>Cyborg Interview & Preview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=EWiqzGeUFt8",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Media Spotlight UK</h4>
          <p>Weigh-Ins (Amber & Brooklyn) (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=ZBR6872rBQc",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Media Spotlight UK</h4>
          <p>Cris Cyborg Interview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=WazYXyxJjtg",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Media Spotlight UK</h4>
          <p>Aria Wild Interview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=RVQlHXvhsYw",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Media Spotlight UK</h4>
          <p>Warren Interview #2 (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=-2HfzJYTyeM",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Media Spotlight UK</h4>
          <p>Gareth A Davies Interview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=O1oTYZFCqn8",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Media Spotlight UK</h4>
          <p>Joel Frimpong Interview (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=XcVAd49Djxo",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Media Spotlight UK</h4>
          <p>Amber Interview #2 (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?si=P8LB80E8MwTCsP_t&v=hBWL1XhwekA&feature=youtu.be",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Media Spotlight UK</h4>
          <p>Amber Interview #1 (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=f_744OwHZZs",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Media Spotlight UK</h4>
          <p>Warren Interview #1 (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=yT6n10ylx2o",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Planet Sport</h4>
          <p>News Announce (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.planetsport.com/boxing/news/crypto-fight-night-cris-cyborg-headlines-london-card-love-island-star-jack-fincham-also-features",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>MMA Fighting</h4>
          <p>Cyborg Finish (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.mmafighting.com/2024/6/16/24179340/watch-cris-cyborg-destroy-another-opponent-with-brutal-knockout-in-boxing-match-crypto-fight-night",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Sporting News</h4>
          <p>Cyborg Finish (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.sportingnews.com/us/mma/news/cris-cyborg-knockout-video-boxing-aria-wild/614cf09d5687dfc9b5ad28c6",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>MMA News</h4>
          <p>Cyborg Finish (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.mmanews.com/news/cris-cyborg-knockout-win-boxing-pfl-frustrations-continue",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>MMA Mania</h4>
          <p>Cyborg Finish (Off Chain)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.mmamania.com/2024/6/15/24179374/mma-legend-cris-cyborg-delivers-brutal-knockout-punch-boxing-beatdown-video-ufc-pfl-bellator-watch",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Sun Boxing</h4>
          <p>Interview w Jack Fincham (CFN4)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.thesun.co.uk/sport/27383930/jack-fincham-body-transformation-love-island-boxing-diet/",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Mirror Boxing</h4>
          <p>Interview w Jack Fincham (CFN4)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.mirror.co.uk/sport/boxing/jack-fincham-boxing-fight-time-32622376",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>OK!</h4>
          <p>Interview w Jack Fincham (CFN4)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.ok.co.uk/celebrity-news/love-islands-jack-fincham-opens-32626180",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Planet Sport</h4>
          <p>Video News/Preview (CFN4)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.planetsport.com/boxing/news/love-island-jack-fincham-reveals-how-boxing-brought-him-out-addiction-self-destruct-mode",
                  "_blank"
                )
              }
            />
          </div>
        </li>
        <li>
          <h4>Irish Sun</h4>
          <p>Video News (CFN4)</p>
          <div className="button-container">
            <Button
              buttonStyle="secondary"
              children="View Press"
              onClick={() =>
                window.open(
                  "https://www.thesun.ie/tv/12779000/love-island-missing-dubai-drugs-weight-loss/",
                  "_blank"
                )
              }
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default PressReleaseWrapper;
