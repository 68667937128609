import React, { useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import WatchLiveStreamWrapper from "../../components/Wrappers/LiveStreamWrapper/WatchLiveStreamWrapper";
import PastEventsCatalogWrapper from "../../components/Wrappers/PastEventsWrapper/PastEventsCatalog";
import { Event } from "../../types/types";

const LiveStreamPage: React.FC = () => {
  const [selectedEvent, setSelectedEvent] = useState<Event>({
    id: "0",
    title: "CFN OFF-CHAIN 1",
    subTitle: "LONDON | 15 JUN 2024",
    videoUrls: [
      "https://www.youtube.com/embed/q-sVhNaSJgI",
      "https://www.youtube.com/embed/6KY6MS8rp4w",
      "https://www.youtube.com/embed/0mUQZfJb2sk",
      "https://www.youtube.com/embed/oMNzUlCpEhs",
      "https://www.youtube.com/embed/XC6O9uoe3Lg",
      "https://www.youtube.com/embed/NV8LuRHUy6g",
      "https://www.youtube.com/embed/shpCxJCAf5A",
      "https://www.youtube.com/embed/sFuxQqQ570s",
      "https://www.youtube.com/embed/WqYh5sHhxvk",
      "https://www.youtube.com/embed/G-MkWcVNtpQ",
      "https://www.youtube.com/embed/yojxxyvsnr0",
      "https://www.youtube.com/embed/EvFm7aaQIPI",
    ],
    img: "",
    bannerUrl: "",
  });

  const handleEventSelection = (event: Event) => {
    setSelectedEvent(event);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LayoutDefault showFeaturedCarousel={false}>
        <WatchLiveStreamWrapper selectedEvent={selectedEvent} />
        <PastEventsCatalogWrapper onSelectEvent={handleEventSelection} />
      </LayoutDefault>
    </>
  );
};

export default LiveStreamPage;
