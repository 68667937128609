import React from "react";
import "./styles.scss";
import TitleHr from "../../Hr/TitleHr";
import Paragraph from "../../Texts/Paragraph";
import VideoPlayer from "../../VideoPlayer";
import sponsorVideo from "../../../assets/videos/Sponsors-.mp4";
import Button from "../../Buttons/Button";

const handleEmail = () => {
  window.location.href = "mailto:support@cfn.wtf";
};

const AboutUsWrapper: React.FC = () => {
  return (
    <div id="about-us-wrapper">
      <div className="content-wrapper-inner">
        <TitleHr title={"ABOUT US"} titleColor={"white"} mainTitle={true} />
        <Paragraph
          text={`CFN has successfully merged the adrenaline-packed world of 
						boxing with the ever-evolving sphere of crypto in the Middle East. 
						By strategically aligning itself with the sport, CFN has created a
						symbiotic relationship wherein boxing events serve as a promotional
						platform for the crypto brand and, in return, the crypto realm 
						provides a fresh source of financial backing and global attention 
						to the sport. This ingenious move not only taps into the massive
						following of boxing enthusiasts but also resonates with the
						tech-savvy generation, making it a winning combination in
						a region that has shown increasing interest in both sectors.`}
          color="faded"
        />
        <div className="video-wrapper">
          <VideoPlayer videoSrc={sponsorVideo} />
        </div>
      </div>
      <div className="buttons-wrapper">
        <Button
          buttonStyle="primary"
          children="Get In Touch"
          onClick={handleEmail}
        />
      </div>
    </div>
  );
};

export default AboutUsWrapper;
