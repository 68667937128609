import React, { ReactNode } from "react";
import "./styles.scss";
import TitleParagraph from "../../Texts/TitleParagraph";
import Title from "../../Texts/Title";
import Paragraph from "../../Texts/Paragraph";

interface TitleHrProps {
  title: string;
  titleColor: "white" | "black";
  mainTitle: boolean;
  subTitle?: string;
  subTitleColor?: "white" | "black" | "gray-dark" | "dust" | "gray";
}

const TitleHr: React.FC<TitleHrProps> = (props) => {
  const { title, titleColor, mainTitle, subTitle, subTitleColor } = props;
  return (
    <div id="title-hr-wrapper">
      <div className="section-title-wrapper">
        <div className="line"></div>
        <div className="title">
          <Title color={titleColor} mainTitle={mainTitle}>
            {title}
          </Title>
        </div>
        <div className="line"></div>
      </div>
      <Paragraph text={subTitle} color={subTitleColor} />
    </div>
  );
};

export default TitleHr;
