import React from "react";
import "./styles.scss";

interface FighterImageProps {
  img: string;
  firstName?: string;
  lastName?: string;
}

const FighterImage: React.FC<FighterImageProps> = (props) => {
  const { img, firstName, lastName } = props;
  return (
    <div id="fighter-image-wrapper">
      <div className="fighter-name-wrapper">
        <div className="first-name">{firstName}</div>
        <div className="last-name">{lastName}</div>
      </div>
      <div className="fighter-border"></div>
      <img src={img} alt="Fighter" />
    </div>
  );
};

export default FighterImage;
