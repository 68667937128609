import React from "react";
import "./styles.scss";
import TitleHr from "../../../Hr/TitleHr";
import { useNavigate } from "react-router-dom";
import { Event } from "../../../../types/types";

import cfnOff2 from "../../../../assets/images/pastEvents/cfn-off2/CFN SINGAPORE 2024 - COMING SOON 1080x1350 (1).jpg";
import pastEvent01 from "../../../../assets/images/pastEvents/CFN1 - WATCH THE STREAM.jpg";
import pastEvent02 from "../../../../assets/images/pastEvents/CFN2 - WATCH THE STREAM.jpg";
import pastEvent03 from "../../../../assets/images/pastEvents/CFN3 - WATCH THE STREAM.jpg";
import pastEvent04 from "../../../../assets/images/pastEvents/CFN4 - WATCH THE STREAM.jpg";
import pastEvent05 from "../../../../assets/images/pastEvents/CFN LONDON - WATCH THE STREAM.jpg";

interface PastEventsCatalogWrapperProps {
  onSelectEvent: (event: Event) => void;
}

const PastEventsCatalogWrapper: React.FC<PastEventsCatalogWrapperProps> = ({
  onSelectEvent,
}) => {
  const events: Event[] = [
    {
      id: "1",
      img: cfnOff2,
      title: "CFN OFF-CHAIN 2",
      subTitle: "SINGAPORE | 19 SEP 2024",
      videoUrls: [],
      bannerUrl: "",
    },
    {
      id: "2",
      img: pastEvent05,
      title: "CFN OFF-CHAIN 1",
      subTitle: "LONDON | 15 JUN 2024",
      videoUrls: [
        "https://www.youtube.com/embed/q-sVhNaSJgI",
        "https://www.youtube.com/embed/6KY6MS8rp4w",
        "https://www.youtube.com/embed/0mUQZfJb2sk",
        "https://www.youtube.com/embed/oMNzUlCpEhs",
        "https://www.youtube.com/embed/XC6O9uoe3Lg",
        "https://www.youtube.com/embed/NV8LuRHUy6g",
        "https://www.youtube.com/embed/shpCxJCAf5A",
        "https://www.youtube.com/embed/sFuxQqQ570s",
        "https://www.youtube.com/embed/WqYh5sHhxvk",
        "https://www.youtube.com/embed/G-MkWcVNtpQ",
        "https://www.youtube.com/embed/yojxxyvsnr0",
        "https://www.youtube.com/embed/EvFm7aaQIPI",
      ],
      bannerUrl: "",
    },
    {
      id: "3",
      img: pastEvent04,
      title: "CFN 4",
      subTitle: "LA PERLE, DUBAI | 19 APR 2024",
      videoUrls: ["https://www.youtube.com/embed/CavR4INPQK4"],
      bannerUrl: "",
    },
    {
      id: "4",
      img: pastEvent03,
      title: "CFN 3",
      subTitle: "YUME, DUBAI | 24 NOV 2023",
      videoUrls: ["https://www.youtube.com/embed/FYEVw6A3rdI"],
      bannerUrl: "",
    },
    {
      id: "5",
      img: pastEvent02,
      title: "CFN 2",
      subTitle: "YUME, DUBAI | 12 NOV 2022",
      videoUrls: [
        "https://www.youtube.com/embed/uBNKKremSCU",
        "https://www.youtube.com/embed/NS2OE1FN1j8",
        "https://www.youtube.com/embed/2VH2K61ZWsA?si=tNvPukZIS6Na0pRh",
      ],
      bannerUrl: "",
    },
    {
      id: "6",
      img: pastEvent01,
      title: "CFN 1",
      subTitle: "LA PERLE, DUBAI | 16 OCT 2021",
      videoUrls: ["https://www.youtube.com/embed/hGCWxVXl8Ug"],
      bannerUrl: "",
    },
  ];

  const handleSelectEvent = (event: Event) => {
    window.scrollTo(0, 0);
    onSelectEvent(event);
  };

  return (
    <div id="past-events-catalog-wrapper">
      <div className="title-hr-wrapper">
        <TitleHr
          title={"PAST EVENTS"}
          titleColor={"white"}
          subTitleColor={"dust"}
          mainTitle={false}
        />
      </div>
      <div className="past-events-wrapper">
        <div className="past-events-row">
          {events.map((item) => (
            <div
              key={item.id}
              className="past-event-item"
              onClick={() => handleSelectEvent(item)}
            >
              <img src={item.img} alt={`Past Event ${item.id}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PastEventsCatalogWrapper;
