import React, { useEffect, useState } from "react";
import "./styles.scss";
import TitleHr from "../../../Hr/TitleHr";
import { Event } from "../../../../types/types";
import Button from "../../../Buttons/Button";

interface WatchLiveStreamWrapperProps {
  selectedEvent: Event;
}

const WatchLiveStreamWrapper: React.FC<WatchLiveStreamWrapperProps> = ({
  selectedEvent,
}) => {
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string>(
    selectedEvent.videoUrls[0]
  );

  useEffect(() => {
    setCurrentVideoUrl(selectedEvent.videoUrls[0]);
  }, [selectedEvent]);

  const handleVideoSelection = (url: string) => {
    setCurrentVideoUrl(url);
  };
  return (
    <div id="watch-live-stream-wrapper">
      <div className="title-hr-wrapper">
        <TitleHr
          title={selectedEvent.title}
          titleColor={"white"}
          subTitle={selectedEvent.subTitle}
          subTitleColor="dust"
          mainTitle
        />
      </div>
      <div className="live-stream-wrapper">
        <iframe
          src={currentVideoUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video"
        ></iframe>
        {selectedEvent.videoUrls.length > 1 && (
          <div className="video-selection-buttons">
            {selectedEvent.videoUrls.map((url, index) => (
              <Button
                buttonStyle="tertiary"
                children={`FIGHT ${index + 1}`}
                onClick={() => handleVideoSelection(url)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default WatchLiveStreamWrapper;
